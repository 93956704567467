import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLongArrowAltRight, faMapMarkedAlt, faPhone, faX } from '@fortawesome/free-solid-svg-icons'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons/faMapMarker'
import { faFacebook, faInstagram, faPinterest, faTwitter, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { useSelector } from 'react-redux'
import { BASE_URL } from '../../constants/Constant'
import { Link, useNavigate } from 'react-router-dom'
const Footer = ({ onNavigate, currentPath }) => {
    const navigate = useNavigate();


    const content = useSelector((state) => state.headerFoooter);
    const cityData = useSelector((state) => state.city);
    console.log(cityData, "this ius")
    const propertyTypeData = useSelector((state) => state.propertyType);
    const listingTypeData = useSelector((state) => state.listingType);
    const pairs = cityData.data.flatMap(city =>
        propertyTypeData.data.flatMap(propertyType =>
            listingTypeData.data.map(listingType => ({
                city,
                propertyType,
                listingType
            }))
        )
    );

    // const selectedCity = JSON.parse(localStorage.getItem('selectedCity'));
    // const selectedPropertyType = JSON.parse(localStorage.getItem('selectedPropertyType'));
    // const selectedListingType = JSON.parse(localStorage.getItem('selectedListingType'));

    // const formatUrlParam = (param) => {
    //     return param
    //         .toLowerCase()
    //         .replace(/\s+/g, '-')
    //         .replace(/[^a-z0-9-]/g, '');
    // };

    // console.log(selectedCity, selectedListingType, selectedPropertyType, "okokkkokokokoko")
    // const formattedPtype = formatUrlParam(selectedPropertyType.name);
    // const formattedLtype = formatUrlParam(selectedListingType.name);
    // const formattedCity = formatUrlParam(selectedCity.name);

    // const dynamicUrl = `/${formattedPtype}/${formattedLtype}/in/${formattedCity}`;


    const shuffledPairs = pairs.sort(() => Math.random() - 0.5);
    const limitedPairs = shuffledPairs.slice(0, 14);



    const handleClick = (c) => {
        console.log(c, "this is c");
        console.log("Link => ", c.url);
    
        // Extract details from dynamic URL or object 'c'
        const { propertyType, listingType, city } = extractDetails(c.url); // Assuming 'c.url' has the details
    
        // Navigate and pass the state with property details and extracted information
        navigate(`/properties${c.url}`, {
            replace: true,
            state: { propertyDetails: { ...c, propertyType: propertyType,
                listingType: listingType,
                city: city } } 
        });
        // window.location.reload()
    };
    function extractDetails(inputString) {
        // Convert string to lower case for uniformity in matching
        const lowerCasedString = inputString.toLowerCase();
      
        // Split the string by hyphens
        const parts = lowerCasedString.split('-');
    
        // Identify the listing type based on "for sale" or "for rent"
        const listingTypeIndex = parts.findIndex(part => part === 'for' && (parts[parts.indexOf(part) + 1] === 'sale' || parts[parts.indexOf(part) + 1] === 'rent'));
        
        // If 'for sale' or 'for rent' is found, construct the listingType
        let listingType = null;
        if (listingTypeIndex !== -1) {
            listingType = `for ${parts[listingTypeIndex + 1]}`;
        }
    
        // Extract propertyType before listingType and remove any "/"
        let propertyType = listingTypeIndex !== -1 ? parts.slice(0, listingTypeIndex).join(' ') : null;
        propertyType = propertyType ? propertyType.replace(/\//g, ' ').trim() : null;  // Remove any "/"
    
        // Extract city after listingType and remove "in"
        let city = listingTypeIndex !== -1 ? parts.slice(listingTypeIndex + 2).join(' ') : null;
        city = city ? city.replace(/\bin\b/g, '').trim() : null;  // Remove "in"
        
        return {
            propertyType: propertyType ? capitalize(propertyType) : null,
            listingType: listingType ? capitalize(listingType) : null,
            city: city ? capitalize(city) : null,
        };
    }
    
    // Helper function to capitalize first letter of each word
    function capitalize(str) {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    }
    return (
        <>
            <footer className="footer font-size-16 text-secondary font-weight-normal" style={{ background: "#f9f9f9 url(images/foorer-bg.png) no-repeat", backgroundPosition: "center" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-3">
                            <div className="mb-3 mb-md-4"><Link className="footer-logo" to="/"><img src={BASE_URL + '/' + content.data.logo} className="img-fluid" /></Link></div>

                            <div className="form-group home-footer position-relative d-flex line-hight-4">
                                {content.data.description}
                            </div>
                            <div className="mt-3 mt-md-4">
                                <Link to="/about-us" className="btn btn-danger font-weight-semibold rounded-pill font-size-14 text-capitalize px-3 py-2"><span className="d-block px-3 py-md-1">View More    <FontAwesomeIcon icon={faLongArrowAltRight} className='ms-2 font-size-12' /></span></Link>
                            </div>


                        </div>

                        <div className="col-md-12 col-lg-3 col-xl-2 my-4 my-lg-0 pb-1 py-2 py-lg-0">
                            <div className="ps-lg-4">
                                <h3 className="text-capitalize font-size-24 text-danger font-weight-bold mb-3 mb-md-4 mb-xxl-5">Quick Link</h3>
                                <ul className="list-unstyled mt-3 mt-md-4 p-0 row quicklinks mb-0">
                                    <li className="py-1 py-lg-2 col-6 col-md-4 col-lg-12"><Link className="text-secondary text-decoration-none" to="/">Home</Link></li>
                                    {/* <li className="py-1 py-lg-2 col-6 col-md-4 col-lg-12"><Link className="text-secondary text-decoration-none" to="/our-properties">Our Properties</Link></li> */}
                                    <li className="py-1 py-lg-2 col-6 col-md-4 col-lg-12">
                                        <Link className="text-secondary text-decoration-none" to='/properties/all'>
                                        Hot Properties
                                        </Link>
                                    </li>
                                    <li className="py-1 py-lg-2 col-6 col-md-4 col-lg-12"><Link className="text-secondary text-decoration-none" to="/about-us">About Us </Link></li>
                                    <li className="py-1 py-lg-2 col-6 col-md-4 col-lg-12"><Link className="text-secondary text-decoration-none" to="/testimonials">Testimonials </Link></li>
                                    <li className="py-1 py-lg-2 col-6 col-md-4 col-lg-12"><Link className="text-secondary text-decoration-none" to="/contact-us">Contact Us</Link></li>
                                    <li className="py-1 py-lg-2 col-6 col-md-4 col-lg-12"><Link className="text-secondary text-decoration-none" to="/trems-and-condition">Terms &amp; Conditions </Link></li>
                                    <li className="py-1 py-lg-2 col-6 col-md-4 col-lg-12"><Link className="text-secondary text-decoration-none" to="/privacy-policy">Privacy policy</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-md-12 col-lg-6 col-xl-7">
                            <div className="ps-lg-4">

                                <ul className="list-unstyled mt-0 mt-lg-5 pt-lg-2 p-0 row quicklinks mb-0">
                                    {content.data?.seoLinks?.map((link, index) => (

                                        <li key={index} className="py-1 py-lg-2 col-6 col-md-6 col-lg-6 col-xl-6 text-secondary text-decoration-none"


                                        >
                                            <a className="text-secondary text-decoration-none"
                                        
                                                onClick={() => handleClick(link)}
                                          
                                            >
                                                {link?.title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>

                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="row my-4">

                                <div className="col-md-6 col-lg-3 line-hight-3 text-secondary my-3">
                                    <div className="d-flex">
                                        <div className="footercall rounded-circle bg-danger rounded-circle p-3 me-3 d-flex align-items-center justify-content-center"> <FontAwesomeIcon icon={faMapMarkedAlt} className='text-white' /></div>
                                        <div>
                                            <h6 className="font-weight-semibold font-size-18 text-dark mb-2">Address</h6>
                                            {content.data.address}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3 line-hight-3 text-secondary my-md-3">
                                    <div className="d-flex">
                                        <div className="footercall rounded-circle bg-danger rounded-circle p-3 me-3 d-flex align-items-center justify-content-center"><FontAwesomeIcon icon={faPhone} className='text-white' /></div>
                                        <div>
                                            <a href={`tel:${content.data.headerPhone}`} className="text-decoration-none text-dark">
                                                <h6 className="font-weight-semibold font-size-18 text-dark mb-2">Phone No.</h6>
                                                {content.data.headerPhone}
                                            </a>

                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-3 line-hight-3 text-secondary my-3">
                                    <div className="d-flex">
                                        <div className="footercall rounded-circle bg-danger rounded-circle p-3 me-3 d-flex align-items-center justify-content-center">
                                            <FontAwesomeIcon icon={faEnvelope} className='text-white' />
                                        </div>

                                        <div>
                                            <h6 className="font-weight-semibold font-size-18 text-dark mb-2">Email</h6>
                                            {/* <a href={`mailto:${content.data.email}`} className="text-dark text-decoration-none">
                                                {content.data.email}
                                            </a> */}
                                            <a href={`mailto:${content.data.email}`} target="_blank" className="text-dark text-decoration-none">
                                                {content.data.email}
                                            </a>
                                        </div>
                                    </div>
                                </div>


                                <div className="col-md-6 col-lg-3 my-3">
                                    <div className="social_media_footer">
                                      
                                        <a
                                            className="text-white text-center me-3 rounded-circle d-inline-block facebook"
                                            href={content.data?.facebookLink?.startsWith('http') ? content.data.facebookLink : `https://${content.data.facebookLink}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faFacebook} className="text-white" />
                                        </a>

                                        <a
                                            className="text-white text-center me-3 rounded-circle d-inline-block twitter"
                                            href={content.data?.twitterLink?.startsWith('http') ? content.data.twitterLink : `https://${content.data.twitterLink}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faXTwitter} className="text-white" />
                                        </a>

                                        <a
                                            className="text-white text-center me-3 rounded-circle d-inline-block pinterest"
                                            href={content.data?.pinterestLink?.startsWith('http') ? content.data.pinterestLink : `https://${content.data.pinterestLink}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faYoutube} className="text-white" />
                                        </a>

                                        <a
                                            className="text-white text-center rounded-circle d-inline-block instagram"
                                            href={content.data?.instagramLink?.startsWith('http') ? content.data.instagramLink : `https://${content.data.instagramLink}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <FontAwesomeIcon icon={faInstagram} className="text-white" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="text-dark text-center text-md-start font-size-13 py-3 border-top">
                        <div className="container">
                            <div className="row justify-content-between">
                                <div className="col-md-12 text-center">Copyright {new Date().getFullYear()}  <a href="#" className="text-danger font-weight-semibold text-decoration-none"><u>panoralands.in</u></a> by <a href="https://www.apponix.com/" target='_blank' className="text-danger font-weight-semibold text-decoration-none"><u>Apponix Technologies Pvt Ltd</u></a></div>
                            </div>
                        </div>
                    </div>
                </div></footer>
        </>
    )
}

export default Footer